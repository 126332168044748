.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
            .completeButton {
              display: flex;
              justify-content: center;
              padding: 2px 5px;
              border-radius: 5px;
              color: rgb(33, 220, 20);
              border: 1px dotted rgb(33, 220, 20);
              cursor: pointer;
            }
            .completeButton:hover {
              padding: 2px 5px;
              border-radius: 5px;
              color: white;
              border: 1px dotted rgb(33, 220, 20);
              background-color: rgb(33, 220, 20);
              cursor: pointer;
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
    
  }

}
