.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 2px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #7451f8;
          background-color: #7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: start;
          
    
          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              display: flex;
              margin-bottom: 10px;
              font-size: 14px;
              width: 1000px;
              justify-content: space-between;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
            .buttons{
              display: flex;
              gap: 10px;
              .resetButton {
                padding: 5px 5px;
                border-radius: 5px;
                color: darkblue;
                border: 2px solid darkblue;
                cursor: pointer;
              }
              .resetButton:hover {
                background-color: darkblue;
                color: white;
                border: 2px solid darkblue;
                cursor: pointer;
              }
              .deleteButton {
                padding: 5px 5px;
                border-radius: 5px;
                color: red;
                text-align: center;
                width: 100px;
                border: 2px solid red;
                cursor: pointer;
              }
              .deleteButton:hover {
                background-color: red;
                color: white;
                border: 2px solid red;
                cursor: pointer;
              }
            }
          }
          .detailsEdit{
            display: grid;
            gap: 20px;
            width: 100%;
            grid-template-columns: auto auto auto;
            .formInput{
            
              width: 50%;
              .itemValue{
                border: 2px solid rgba(204, 204, 235, 0.596);
                padding: 5px;
                border-radius: 10px;
              }
            }
           
          }
          .saveButton {
            cursor: pointer;
            padding: 5px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 2px solid rgba(0, 0, 139, 0.596);
            cursor: pointer;
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 2px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 2px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}
