.sidebar {
  flex: 0.7;
  border-right: 0.5px solid rgb(230, 227, 227);
  background-color: white;
  
  
  .top {
    
    border-bottom: 0.5px solid rgb(231, 228, 228);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
      padding: 20px;
      justify-content: space-between;

  }
    .menuIcon{
      display: none;
      border: none;
      background-color: white;
      @media screen and (max-width: 900px) {
        display: block;
    }
    }
  }


  .center {
    display: block;
    @media screen and (max-width: 900px) {
      display: none;
      padding-left: 10px;

  }
    padding-left: 10px;
    .icon{
      display: none;
    
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
   
     
      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        &.selected{
          background-color: #ece8ff;
        }
        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          font-size: 18px;
          color: #3E1B11;
        }

        span{
            font-size: 13px;
            font-weight: 600;
            color: #888;
            margin-left: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;
   
    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }

}

